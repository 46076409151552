import { urlFromPath } from "../../lib/hooks/useCdn";
import { ImageCarouselBlock as ICBlock } from "../../types/neo_content";
import Carousel from "../Carousel";

export default function ImageCarouselBlock({ block }: { block: ICBlock }) {
    let carouselData = block.imageCarousel.map((data) => ({
        title: data.heading,
        date: data.subHeading,
        image: urlFromPath(data.image),
        credit: data.note,
    }));

    return <Carousel items={carouselData}></Carousel>;
}
