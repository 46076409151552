import { PageContent } from "../../types/content";

export default function findPage(pages: PageContent[], slug: string): PageContent | null {
    let page: PageContent | null = null;

    pages.every(check => {
        if (check.slug === slug) {
            page = check;
            return false;
        }

        if (!check.subMenu) return true;

        const found = check.subMenu.find(subCheck => subCheck.slug === slug);

        if (typeof found === 'undefined') return true;

        page = found;
        return false;
    });

    return page;
}