import { GhanaContact } from "../../types/fields";
import ContactLink from "../ContactLink";

export default function GhanaHelpItem({ contact }: { contact: GhanaContact }) {
    return (
        <div className="bg-white p-4 rounded-xl mx-4 mb-3">
            <h2 className="font-bold mb-4 text-xl">{contact.contactName}</h2>
            <p className="mb-4">{contact.contactDescription}</p>
            {contact.phone && <PhoneInfo phone={contact.phone} />}
            {contact.email && <EmailInfo email={contact.email} />}
        </div>
    );
}

function PhoneInfo({ phone }: { phone: string }) {
    return (
        <div className="mb-4">
            <h4 className="font-bold">Phone: </h4>
            <ContactLink contact={phone} />
        </div>
    );
}

function EmailInfo({ email }: { email: string }) {
    return (
        <div>
            <h4 className="font-bold">Email: </h4>
            <ContactLink contact={email} />
        </div>
    );
}
