import { IonContent, IonIcon, IonPage } from "@ionic/react";
import Header from "../components/Header";
import HomePageItem from "../components/HomePage/HomePageItem";
import PageTitle from "../components/PageTitle";
import SiteContent from "../types/content";
import "./HomePage.css";

export default function HomePage({ siteData }: { siteData: SiteContent }) {
    return (
        <IonPage>
            <Header />
            <IonContent fullscreen color="light" className="">
                <PageTitle>
                    {siteData.globals.homeScreenHeading || "Home"}
                </PageTitle>

                {siteData.pages
                    .filter((page) => page.parent === null)
                    .map((page, index) => (
                        <HomePageItem key={index} page={page} />
                    ))}

                <div className="text-center text-6xl">
                    <IonIcon icon="assets/icon/globe.svg" />
                </div>
            </IonContent>
        </IonPage>
    );
}
