import { RichTextBlock as RTBlock } from "../../types/neo_content";
import RichText from "../fields/RichText";

export default function RichTextBlock({ block }: { block: RTBlock }) {
    return (
        <>
            {block.richText.map((data, index) => (
                <RichText
                    key={index}
                    darkBackground={data.darkBackground}
                    centreAlign={data.centreAlign}
                >
                    {data.richText}
                </RichText>
            ))}
        </>
    );
}
