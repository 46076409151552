import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { recordNav } from "../../lib/util/analytics";
import { resetData } from "../../lib/util/user";
import { PageContent } from "../../types/content";
import { NavCircle } from "../NavCircle";

interface MenuItemProps {
    page: PageContent;
    activePath: string;
    topLevel?: boolean;
    setOpen: any;
}

function closeMenu() {
    // @ts-ignore
    const btn = document.querySelector(
        '.main-menu > ion-header ion-buttons[slot="end"] ion-menu-button'
    );
    // @ts-ignore
    btn.click();
}

export default function MenuItem({
    page,
    topLevel,
    activePath,
    setOpen,
}: MenuItemProps) {
    const pathSlug = activePath.split("/").pop();

    const active = page.slug === pathSlug;

    const history = useHistory();

    let url = "/page";

    if (page.parent) {
        url += `/${page.parent.slug}`;
    }

    url += `/${page.slug}`;

    function onclick(e: any) {
        if (page.slug === "preferences") {
            closeMenu();
            resetData();
            recordNav("preferences", {
                action: "click",
            });
            return;
        }

        if (page.parent) {
            const accordion = e.target.closest(".accordion");

            if (!accordion.classList.contains("open")) {
                accordion.querySelector(":scope > .parent").click();
            }
        }

        if (topLevel) {
            setOpen(null);
        }

        if (e.target.offsetParent !== null) {
            closeMenu();
            recordNav(page.slug, { source: "menu", action: "click" });
        } else {
            recordNav(page.slug, { source: "home", action: "click" });
        }

        history.replace(url);
    }

    return (
        <div
            data-url={url}
            className={clsx(
                "item flex reversible justify-between items-center",
                active
                    ? "active font-black bg-light-gray text-deep-blue"
                    : "font-bold"
            )}
            onClick={onclick}
        >
            <label
                className={clsx("uppercase", topLevel && "font-black text-lg")}
            >
                {page.pageHeading}
            </label>
            <NavCircle className={clsx(active && "invisible")} />
        </div>
    );
}
