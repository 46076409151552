import { Analytics } from "aws-amplify";
import store from "./storage";

export function recordNav(to: string, data: any = null) {
    record("navigation", {category: to, ...(data || {})});
}

export default function record(type: "navigation" | "interaction", data?: any) {
    const slug = document.location.pathname.split("/").reverse()[0];

    store.get("user_data").then((user_data) => {
        Analytics.record({
            name: type,
            attributes: {
                c_current_page: slug,
                c_env: process.env.REACT_APP_ENVIRONMENT,
                c_region: user_data?.region || null,
                c_locale: user_data?.locale || null,
                ...(data || {}),
            },
        });
    });
}
