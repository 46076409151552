import { useEffect, useState } from "react";
import { PageContent } from "../../types/content";
import MenuParent, { updateMaxHeights } from "./MenuParent";
import MenuItem from "./MenuItem";
import { useLocation } from "react-router-dom";
import "./AccordionGroup.css";

interface Props {
    menuItems: PageContent[];
}

export default function AccordionMenu({ menuItems }: Props) {
    const location = useLocation();
    const activePath = location.pathname;
    let pathParts = activePath.split("/").reverse();

    const activeSlug = pathParts[pathParts[1] === "page" ? 0 : 1];

    const [openSlug, setOpenSlug] = useState(activeSlug as string | null);

    function setOpen(slug: string, open: boolean = true) {
        if (open) {
            setOpenSlug(slug);
        } else if (openSlug === slug) {
            setOpenSlug(null);
        }
    }

    useEffect(() => {
        updateMaxHeights();
    }, []);

    return (
        <div className="global-accordion-group">
            {menuItems.map((page) => {
                if (page.subMenu && page.subMenu.length) {
                    const pageUrl = `/page/${page.slug}`;
                    const isActive =
                        activePath.substring(0, pageUrl.length) === pageUrl;

                    return (
                        <MenuParent
                            key={page.slug}
                            page={page}
                            open={openSlug === page.slug}
                            active={isActive}
                            activePath={activePath}
                            setOpen={setOpen}
                        />
                    );
                } else {
                    return (
                        <MenuItem
                            key={page.slug}
                            activePath={activePath}
                            topLevel={true}
                            page={page}
                            setOpen={setOpen}
                        />
                    );
                }
            })}
        </div>
    );
}


