import { IonImg } from "@ionic/react";
import clsx from "clsx";
import { urlFromPath } from "../../lib/hooks/useCdn";
import { IconBreak as IconBreakData } from "../../types/fields";

interface Props extends IconBreakData {
    className?: string;
}

export default function IconBreak({
    className,
    darkBackground,
    align,
    icon,
}: Props) {
    const path = urlFromPath(icon);

    if (path === "") {
        return <></>;
    }
    return (
        <div
            className={clsx(
                "px-4 pb-4",
                darkBackground && "mt-4 pt-6 bg-deep-blue",
                className,
                ` text-${align}`
            )}
        >
            <IonImg src={path} className="inline-block h-14 w-14" />
        </div>
    );
}
