import { IonIcon } from "@ionic/react";
import { chevronDown } from "ionicons/icons";

export default function ParentIcon() {
    return (
        <span className="w-12 h-12 -bottom-6 bg-white absolute rounded-t-full">
            <IonIcon
                icon={chevronDown}
                className="dd-chevron mt-1 text-xl transition-all duration-300 ease-in-out"
            />
        </span>
    );
}