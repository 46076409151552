import { IconBreakBlock as IBBlock } from "../../types/neo_content";
import IconBreak from "../fields/IconBreak";

export default function IconBreakBlock({ block }: { block: IBBlock }) {
    return (
        <>
            {block.iconTextBreak.map((data, index) => (
                <IconBreak key={index} {...data} />
            ))}
        </>
    );
}
