import { IonIcon } from "@ionic/react";
import { videocamOffSharp } from "ionicons/icons";

export default function EmbeddedVideo({ url }: { url: string }) {
    return (
        <div
            className="relative mx-4 p-0 mb-6"
            style={{ padding: "56.25% 0 0 0" }}
        >
            <div
                className="absolute top-0 w-full h-full flex items-center justify-center"
            >
                <IonIcon icon={videocamOffSharp} className="text-6xl" />
            </div>
            <iframe
                id="embed-test"
                src={url}
                className="absolute top-0 left-0 w-full h-full"
                frameBorder="0"
                title="Video Player"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
            ></iframe>
        </div>
    );
}
