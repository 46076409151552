import { IonApp, setupIonicReact, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";
import useSWR from "swr";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/tailwind.css";

import Amplify from "aws-amplify";
import awsconfig from "./aws-exports";

import Onboarding from "./pages/Onboarding";
import HomePage from "./pages/HomePage";
import Page from "./pages/Page";

import Menu from "./components/Menu/Menu";
import useLocalFetcher from "./lib/hooks/useLocalFetcher";
import { cdnFetcher } from "./lib/hooks/useFetcher";
import restructureSiteData from "./lib/util/content_structure";
import { PageContent } from "./types/content";
import { useState } from "react";
import SplashScreen from "./pages/SplashScreen";

setupIonicReact();
Amplify.configure(awsconfig);

function App() {
  const { data: userData, error: userError } = useSWR(
    "user_data",
    useLocalFetcher
  );

  const [splashTimeout, setSplashTimeout] = useState(new Date(0));

  const onboarded = userData?.region && userData?.locale;

  const startupDataSwr = useSWR(
    onboarded ? null : "content/startup_content.json",
    cdnFetcher
  );

  const url = onboarded ? `content/${userData.locale}.json` : null;

  const { data: rawSiteData, error: cdnError } = useSWR(url, cdnFetcher);

  const userDataUnloaded = userError || !userData;
  const cndDataUnloaded = onboarded && (cdnError || !rawSiteData);
  const waitForSplash = Date.now() < splashTimeout.valueOf();
  
  if (userDataUnloaded || cndDataUnloaded || waitForSplash) {
    return (
      <SplashScreen
        splashTimeout={splashTimeout}
        setSplashTimeout={setSplashTimeout}
        productOfText={
          startupDataSwr.data?.locale_settings[userData?.locale]
            ?.productOfText
        }
      />
    );
  }

  let siteData = null;

  let menuProps: {
    heading: string;
    menuItems: PageContent[];
    dir: "ltr" | "rtl";
  } = {
    heading: "",
    menuItems: [],
    dir: "ltr",
  };

  if (onboarded) {
    siteData = restructureSiteData(rawSiteData);
    menuProps.heading = siteData.globals.menuHeading;
    menuProps.menuItems = siteData.pages;
  }

  const rtl = siteData && siteData.globals.textDirection === "rtl";

  menuProps.dir = rtl ? "rtl" : "ltr";

  const onboardingPage = (
    <Onboarding
      {...userData}
      setSplashTimeout={setSplashTimeout}
      startupDataSwr={startupDataSwr}
    />
  );

  // @ts-ignore
  const homePage = <HomePage siteData={siteData} />;

  const redirect = onboarded ? undefined : (
    <Redirect to={onboarded ? "/home" : "/onboarding"} />
  );

  return (
    <IonApp className={rtl ? "app-rtl" : ""}>
      <IonReactRouter>
        <Menu {...menuProps} />
        <IonRouterOutlet id="main">
          <Route path="*" exact={false}>
            {onboarded ? homePage : onboardingPage}
            <Redirect to={onboarded ? "/home" : "/onboarding"} />
          </Route>
          <Route path="/onboarding">
            {!onboarded ? onboardingPage : <Redirect to="/home" />}
          </Route>
          <Route path="/page/*">
            {/* @ts-ignore */}
            {redirect || <Page pages={siteData.pages} />}
          </Route>
          <Route path="/home" exact={true}>
            {redirect || homePage}
          </Route>
          <Route path="/" exact={true}>
            <Redirect to={onboarded ? "/home" : "/onboarding"} />
          </Route>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
}

export default App;