import {
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonMenu,
    IonMenuButton,
    IonTitle,
    IonToolbar,
} from "@ionic/react";

import { close } from "ionicons/icons";
import { PageContent } from "../../types/content";
import AccordionGroup from "./AccordionGroup";
import "./Menu.css";


interface MenuProps {
    heading: string;
    menuItems: PageContent[];
    dir: "ltr" | "rtl";
}

function Menu({ heading, menuItems, dir }: MenuProps) {
    return (
        <IonMenu
            contentId="main"
            menuId="global-menu"
            type="overlay"
            side={dir === "rtl" ? "start" : "end"}
            className="main-menu"
        >
            <IonHeader>
                <IonToolbar color="tertiary" className="flex">
                    <IonButtons slot="start" className="invisible">
                        <IonMenuButton className="mx-3" autoHide={false}>
                            <IonIcon className="text-white" icon={close} />
                        </IonMenuButton>
                    </IonButtons>
                    <IonTitle className="text-2xl w-full text-center">
                        {heading}
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonMenuButton className="mx-3" autoHide={false}>
                            <IonIcon className="text-white" icon={close} />
                        </IonMenuButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <AccordionGroup menuItems={menuItems} />
            </IonContent>
        </IonMenu>
    );
}

export default Menu;
