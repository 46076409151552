import { useState } from "react";
import IconBreak from "../components/fields/IconBreak";
import PlainText from "../components/fields/PlainText";
import PlainTextBg from "../components/fields/PlainTextBg";
import { urlFromPath } from "../lib/hooks/useCdn";
import { HelpPageContent } from "../types/content";
import CountryHelpInfo from "../components/Help/CountryHelpInfo";
import CountrySelector from "../components/Help/CountrySelector";
import RegionRadioList from "../components/Help/RegionRadioList";
import "./HelpPage.css";
import record from "../lib/util/analytics";

export default function HelpPage({ page }: { page: HelpPageContent }) {
    const [selected, setSelected] = useState(null as number | null);
    const [modalOpen, setModalOpen] = useState(false);

    const selectRegion = (region: number) => {
        if (
            region !== null &&
            typeof region !== "undefined" &&
            region !== selected
        ) {
            record("interaction", {
                category: "country_selector",
                label: page.countryHelpList[region]?.plainText,
            });
        }
        setModalOpen(false);
        setSelected(region);
    };

    const regions = page.countryHelpList.map((country, index) => ({
        value: index,
        label: country.plainText,
        image: urlFromPath(country.image),
    }));

    const selectedCountry =
        selected !== null
            ? page.countryHelpList[selected].plainText
            : undefined;

    return (
        <>
            <PlainText>{page.introText}</PlainText>
            <CountrySelector
                text={selectedCountry}
                placeholderText={page.selectRegionLabel}
                onclick={setModalOpen.bind(null, true)}
            />
            <RegionRadioList
                isOpen={modalOpen}
                onClose={setModalOpen.bind(null, false)}
                setSelected={selectRegion}
                selected={selected}
                items={regions}
            />
            {selected !== null && (
                <CountryHelpInfo country={page.countryHelpList[selected]} />
            )}
            <PlainTextBg fields={page.plainTextBg} />
            {page?.iconTextBreak.length > 0 && (
                <IconBreak
                    className="mt-0 pt-3"
                    align="left"
                    icon={page.iconTextBreak[0].icon}
                    darkBackground={false}
                />
            )}
        </>
    );
}
