import { IonList, IonRadioGroup } from "@ionic/react";
import clsx from "clsx";

import RadioListItem from "./RadioListItem";

interface RadioListOption {
    image?: string;
    value: string;
    label: string;
}

interface RadioListProps {
    items: RadioListOption[];
    selected?: string;
    onclick?: any;
    className?: string;
}

function RadioList({ items, selected, onclick, className }: RadioListProps) {
    const ionItems = items.map((item) => (
        <RadioListItem
            value={item.value}
            key={item.value}
            label={item.label}
            image={item.image}
            onclick={onclick}
        />
    ));

    return (
        <IonList className={clsx("radio-list bg-transparent", className || "")}>
            <IonRadioGroup
                value={selected ?? null}
            >
                {ionItems}
            </IonRadioGroup>
        </IonList>
    );
}

export default RadioList;
