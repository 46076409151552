import {
    IonContent,
    IonHeader,
    IonPage,
    IonIcon,
    IonSpinner,
    IonToolbar,
} from "@ionic/react";
import "./Onboarding.css";
import User from "../types/user";
import RegionSelect from "../components/Onboarding/RegionSelect";
import LocaleSelect from "../components/Onboarding/LocaleSelect";
import { StartupData } from "../types/startup_types";
import { setRegion } from "../lib/util/user";

interface Props extends User {
    setSplashTimeout: any;
    startupDataSwr: any;
    prevLocale?: string | null;
}

function Onboarding({ region, startupDataSwr, locale, setSplashTimeout }: Props) {

    const {data, error} = startupDataSwr;

    let content = null;

    console.log(data);

    if (error) {
        content = (
            <h2 className="p-4 mt-8 text-center">
                Failed to load data, please try again later
            </h2>
        );
    } else if (!data) {
        content = (
            <div className="h-5/6 flex items-center justify-center">
                <IonSpinner name="crescent" color="light" className="w-12 h-12" />
            </div>
        );
    } else {
        const startupData: StartupData = data;

        if (!region) {
            content = <RegionSelect countries={startupData.country_locales} />;
        } else {
            if (!(region in startupData.country_locales)) {
                setRegion(null);
            }

            const country = startupData.country_locales[region];

            content = <LocaleSelect country={country} startupData={data} setSplashTimeout={setSplashTimeout} />;
        }
    }

    return (
        <IonPage>
            <IonHeader
                className="onboarding-header px-0 flex bg-white items-center justify-center"
                color="primary"
            >
                <IonToolbar>
                    <div className="flex flex-row justify-center flex-nowrap my-3">
                        <IonIcon className="text-7xl pr-4 mr-4" icon="assets/icon/wf_logo.svg" />
                        <IonIcon className="text-7xl" icon="assets/icon/global_freedom_network.svg" />
                    </div>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen color="tertiary">
                {content}
            </IonContent>
        </IonPage>
    );
}

export default Onboarding;
