import { useState } from "react";

interface AccordionProps {
    className?: string;
    headerWrapClasses?: string;
    bodyWrapClasses?: string;
    headerEl: any;
    bodyEl: any;
}

export default function Accordion(props: AccordionProps) {
    const [open, setOpen] = useState(false);

    function onclick(e: any) {
        const bodyEl = e.target
            .closest(".accordion")
            .querySelector(":scope > div:last-child");
        bodyEl.style["max-height"] = open ? 0 : `${bodyEl.scrollHeight}px`;
        setOpen(!open);
    }

    return (
        <div
            className={
                "accordion " + (open ? "open " : "") + (props.className || "")
            }
        >
            <div onClick={onclick} className={props.headerWrapClasses || ""}>
                {props.headerEl}
            </div>
            <div
                className={
                    "max-h-0 transition-all duration-300 ease-in-out overflow-hidden " +
                    (props.bodyWrapClasses || "")
                }
            >
                {props.bodyEl}
            </div>
        </div>
    );
}