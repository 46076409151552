import {
    IonPopover,
    IonContent,
    IonList,
    IonRadioGroup,
    IonItem,
    IonImg,
    IonLabel,
    IonRadio,
} from "@ionic/react";

import "./RegionRadioList.css";

interface RadioListItem {
    value: number | string;
    label: string;
    image: string;
}

interface RadioListItems {
    isOpen: boolean;
    onClose: () => void;
    selected: number | null;
    setSelected: (e: any) => void;
    items: RadioListItem[];
}

export default function RegionRadioList({
    isOpen,
    onClose,
    selected,
    setSelected,
    items,
}: RadioListItems) {

    // @ts-ignore
    items = items.sort((r1, r2) => 0 - (r1.label.toLowerCase() < r2.label.toLowerCase()));

    return (
        <IonPopover
            isOpen={isOpen}
            size="auto"
            onDidDismiss={onClose}
            className="region-radio-list"
        >
            <IonContent color="white">
                <IonList className="bg-white mb=8">
                    <IonRadioGroup color="white" value={selected}>
                        {items.map((item, key) => (
                            <IonItem
                                key={key}
                                lines="none"
                                className="bg-white p-4 px-0 reversible"
                                onClick={setSelected.bind(null, item.value)}
                            >
                                <IonImg src={item.image} className="h-8 w-8" />
                                <IonLabel
                                    color="dark"
                                    className="m-0 mx-4 mt-1"
                                >
                                    {item.label}
                                </IonLabel>
                                <IonRadio value={item.value} className="mx-4" />
                            </IonItem>
                        ))}
                    </IonRadioGroup>
                </IonList>
            </IonContent>
        </IonPopover>
    );
}
