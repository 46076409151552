export default function CountrySelector({
    text,
    placeholderText,
    onclick,
}: {
    text?: string;
    placeholderText: string;
    onclick: () => void;
}) {
    return (
        <div
            className={
                "country-sel bg-white mx-4 mb-8 py-3 px-4 rounded-xl " +
                "flex flex-row flex-reversible text-reversible flex-nowrap border justify-between items-center"
            }
            onClick={onclick}
        >
            <button
                className={
                    "uppercase flex-grow font-black pt-2 pb-1 text-left text-reversible" +
                    (text ? "" : " text-gray-400")
                }
            >
                {text || placeholderText}
            </button>
            <div className="arrow-wrap flex-center bg-orange w-6 h-6 rounded-full">
                <span className="w-0 h-0" />
            </div>
        </div>
    );
}
