
import { PlainTextBlock as PTBlock } from "../../types/neo_content";
import PlainText from "../fields/PlainText";

export default function PlainTextBlock({block}: {block: PTBlock}) {
    return (
        <>
            {block.plainTextBg.map((data, index) => (
                <PlainText key={index} darkBackground={data.darkBackground}>
                    {data.text}
                </PlainText>
            ))}
        </>
    );
}
