import { IonContent, IonPage } from "@ionic/react";
import Header from "../components/Header";
import { Redirect, useLocation } from "react-router-dom";
import findPage from "../lib/util/find_page";
import {
    ContentPageContent,
    GhanaHelpPageContent,
    HelpPageContent,
    PageContent,
} from "../types/content";
import PageTitle from "../components/PageTitle";
import ContentPage from "./ContentPage";
import HelpPage from "./HelpPage";
import GhanaHelpPage from "./GhanaHelpPage";
import { useEffect } from "react";
import "./Page.css";

function Page({ pages }: { pages: PageContent[] }) {
    const path = useLocation().pathname;
    useEffect(() => {
        // @ts-ignore
        document.querySelector(".page-content")?.scrollToTop();
    });

    if (typeof pages === "undefined") {
        return <Redirect to="/home" />;
    }

    const slug = path.split("/").reverse()[0];
    const pageData = findPage(pages, slug);

    if (pageData === null) {
        return <Redirect to="/home" />;
    } else {
        let page = null;

        switch (pageData.typeHandle) {
            case "contentPage":
                page = <ContentPage page={pageData as ContentPageContent} />;
                break;

            case "helpPage":
                page = <HelpPage page={pageData as HelpPageContent} />;
                break;

            case "ghanaHelpPage":
                page = (
                    <GhanaHelpPage page={pageData as GhanaHelpPageContent} />
                );
                break;

            default:
                return <Redirect to="/home" />;
        }

        return (
            <IonPage color="light">
                <Header />
                <IonContent fullscreen color="light" className="page-content">
                    <PageTitle className="bg-black">
                        {pageData.pageHeading || "No heading"}
                    </PageTitle>
                    {page}
                </IonContent>
            </IonPage>
        );
    }
}

export default Page;
