import SiteContent, { GlobalContent, PageContent } from "../../types/content";
import loadPages from "./page_structure";


export default function restructureSiteData(raw: any): SiteContent {
    const siteContent: SiteContent = {
        globals: raw.globals as GlobalContent,
        pages: loadPages(raw.pages),
    };

    siteContent.pages.unshift({
        id: -2,
        slug: "home",
        typeHandle: "menuPage",
        pageHeading: siteContent.globals.homeMenuLabel
    } as PageContent);

    siteContent.pages.push({
        id: -1,
        slug: "preferences",
        typeHandle: "menuPage",
        pageHeading: siteContent.globals.preferencesLabel || "Preferences"
    } as PageContent);

    return siteContent;
}
