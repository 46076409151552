import { PlainTextBg as PTB } from "../../types/fields";
import PlainText from "./PlainText";

export default function PlainTextBg(data: {
    fields: PTB[];
    className?: string;
}) {
    return (
        <>
            {data.fields.map((pt, i) => (
                <PlainText key={i} darkBackground={pt.darkBackground}>
                    {pt.text}
                </PlainText>
            ))}
        </>
    );
}
