import { useState } from "react";
import { urlFromPath } from "../lib/hooks/useCdn";
import { GhanaHelpPageContent } from "../types/content";
import CountrySelector from "../components/Help/CountrySelector";
import RegionRadioList from "../components/Help/RegionRadioList";
import RichText from "../components/fields/RichText";
import GhanaHelpList from "../components/Help/GhanaHelpList";

export default function GhanaHelpPage({
    page,
}: {
    page: GhanaHelpPageContent;
}) {
    const [selected, setSelected] = useState(null as number | null);
    const [modalOpen, setModalOpen] = useState(false);

    const selectRegion = (region: number) => {
        setModalOpen(false);
        setSelected(region);
    };

    const selectedCountry =
        selected !== null
            ? page.ghanaRegionContacts[selected].heading
            : undefined;

    const regions = page.ghanaRegionContacts.map((region, index) => ({
        value: index,
        label: region.heading,
        image: urlFromPath(region.image),
    }));

    return (
        <>
            {page.richText.map((item, key) => (
                <RichText key={key}>{item.richText}</RichText>
            ))}
            <CountrySelector
                text={selectedCountry}
                placeholderText={page.selectRegionLabel}
                onclick={setModalOpen.bind(null, true)}
            />
            <RegionRadioList
                isOpen={modalOpen}
                onClose={setModalOpen.bind(null, false)}
                setSelected={selectRegion}
                selected={selected}
                items={regions}
            />
            {selected !== null && (
                <GhanaHelpList region={page.ghanaRegionContacts[selected]} />
            )}
            <p />
        </>
    );
}
