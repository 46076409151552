import clsx from "clsx";
import { urlFromPath } from "../../lib/hooks/useCdn";
import { PageContent } from "../../types/content";
import Accordion from "../Accordion";
import DirectIcon from "./DirectIcon";
import HomePageChildItems from "./HomePageChildItems";
import ParentIcon from "./ParentIcon";

interface ItemProps {
    page: PageContent;
}

export default function HomePageItem({ page }: ItemProps) {
    const path = urlFromPath(page.homePageImage);

    const direct = !page.subMenu || !page.subMenu.length;

    const goToPage = () => {
        const url = `/page/${page.slug}`;

        // @ts-ignore
        document
            .querySelector(
                `.main-menu .global-accordion-group .item[data-url="${url}"]`
            )
            // @ts-ignore
            .click();
    };

    const headerEl = (
        <div
            style={{
                backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${path})`,
            }}
            className={clsx(
                "min-h-[6rem] relative flex items-center justify-center rounded-t-xl",
                "bg-no-repeat bg-center bg-cover overflow-hidden text-center",
                direct ? "" : " pt-4 pb-8  "
            )}
            onClick={direct ? goToPage : undefined}
        >
            <h3
                className={clsx(
                    "text-white uppercase font-black text-lg",
                    direct ? "mx-12" : "mx-4"
                )}
            >
                {page.pageHeading}
            </h3>
            {direct ? <DirectIcon /> : <ParentIcon />}
        </div>
    );

    const bodyEl = <HomePageChildItems pages={page.subMenu} />;

    return (
        <Accordion
            headerEl={headerEl}
            bodyEl={bodyEl}
            className="bg-white rounded-xl mx-6 mb-6 shadow overflow-hidden"
        />
    );
}
