import { useState, useEffect } from "react";
import styles from "./Image.module.css";
import { IonImg } from "@ionic/react";

interface ImageProps {
    path: string;
    alt?: string;
    href?: string;
    classes?: string;
    center?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
function Image({ path, alt, href, classes, center }: ImageProps) {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setIsLoading(false);
    }, []);

    if (isLoading) {
        return <></>;
    } else {
        return (
            <div
                className={` ${
                    center
                        ? "flex justify-center items-center text-center"
                        : styles.imageContainer
                }`}
            >
                {href ? (
                    <a href={href}>
                        <IonImg src={path} className={classes} alt={alt} />
                    </a>
                ) : (
                    <IonImg src={path} className={classes} alt={alt} />
                )}
            </div>
        );
    }
}

export default Image;
