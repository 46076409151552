import {
    IonButtons,
    IonButton,
    IonHeader,
    IonMenuButton,
    IonTitle,
    IonToolbar,
    IonIcon,
    useIonRouter,
} from "@ionic/react";

import { chevronBack, ellipsisHorizontal } from "ionicons/icons";
import { useLocation } from "react-router-dom";
import record, { recordNav } from "../lib/util/analytics";
import "./Header.css";

function Header() {
    const path = useLocation().pathname;
    const showBack = !!path.match(/^\/page/);
    const router = useIonRouter();

    function goHome() {
        recordNav("navigation", {action: "back"});
        router.push("/home", "none");
    }

    return (
        <IonHeader>
            <IonToolbar className="bg-orange flex reversible">
                <IonButtons slot="start" className="px-3">
                    <IonButton
                        className={"text-white" + (showBack ? "" : " invisible")}
                        color="white"
                        onClick={showBack ? goHome : undefined}
                    >
                        <IonIcon
                            className="text-white icon-flippable"
                            color="white"
                            icon={chevronBack}
                        />
                    </IonButton>
                </IonButtons>

                <IonTitle
                    class="ion-text-center h-20 py-0"
                    size={"small"}
                >
                    <IonIcon
                        src="assets/icon/FFF.svg"
                        className="w-full text-base mt-1"
                    />
                </IonTitle>

                <IonButtons slot="end" className="px-3">
                    <IonMenuButton
                        menu="global-menu"
                        className="text-white"
                        autoHide={false}
                        onClick={() => {
                            record("interaction", {action: "menu"});
                        }}
                    >
                        <IonIcon
                            className="text-white"
                            color="white"
                            icon={ellipsisHorizontal}
                        />
                    </IonMenuButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>
    );
}

export default Header;
