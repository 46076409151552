import axios from "axios";
import { CONTENT_URL } from "../util/constants";

const axiosInstance = axios.create({
    baseURL: CONTENT_URL,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});

export const cdnFetcher = (url: string) =>
    axiosInstance.get(url).then((res) => res.data);

export const fetcher = (url: string) => axios.get(url).then((res) => res.data);
