import { IonIcon } from "@ionic/react";
import clsx from "clsx";
import { chevronDown } from "ionicons/icons";
import { PageContent } from "../../types/content";
import MenuItem from "./MenuItem";

interface MenuParentProps {
    page: PageContent;
    active: boolean;
    activePath: string;
    open: boolean;
    setOpen: any;
    duration?: number;
}

function setTransition(el: any, prop: string, duration: number) {
    el.style["transition-property"] = "max-height";
    el.style["transition-duration"] = `${duration}ms`;
}


export function updateMaxHeights() {
    const accordions = document.querySelectorAll(
        ".global-accordion-group .accordion"
    );

    accordions.forEach((accordion: any) => {
        const isOpen = accordion.classList.contains("open");

        if (!isOpen) return;

        const items = accordion.querySelector(":scope .accordion-items");
        setTransition(items, "max-height", 300);

        if (items.offsetParent === null) {
            items.style["max-height"] = isOpen ? `unset` : "0px";
        } else {
            const height = accordion.scrollHeight;
            items.style["max-height"] = isOpen ? `${height}px` : "0px";
        }
    });
}

export default function MenuParent({
    page,
    active,
    activePath,
    open,
    setOpen,
    duration = 300,
}: MenuParentProps) {
    function onclick(e: any) {
        const accordion = e.target.closest(".accordion");
        const items = accordion.querySelector(":scope > .accordion-items");

        if (items.offsetParent === null) {
            setTransition(items, "none", 0);
            items.style["max-height"] = open ? "0px" : "unset";

            setOpen(page.slug, !open);
            return;
        }

        // Update style before transition
        updateMaxHeights();

        const height = items.scrollHeight;

        setTransition(items, "max-height", duration);

        // Need a small timeout for the max-height to register
        setTimeout(() => {
            items.style["max-height"] = open ? "0px" : `${height}px`;
            setOpen(page.slug, !open);
        }, 1);
    }

    return (
        <div
            className={clsx(
                "accordion",
                open && "open",
                active && "active bg-white text-deep-blue"
            )}
        >
            <div
                className="parent flex reversible justify-between items-center"
                onClick={onclick}
            >
                <label className="uppercase font-black">{page.pageHeading}</label>
                <span className="icon-wrap flex-center">
                    <IonIcon
                        icon={chevronDown}
                        className="text-2xl transition-all duration-300"
                    />
                </span>
            </div>
            <div className={"accordion-items overflow-hidden"}>
                {page.subMenu?.map((page) => (
                    <MenuItem
                        key={page.slug}
                        activePath={activePath}
                        page={page}
                        setOpen={setOpen}
                    />
                ))}
            </div>
        </div>
    );
}