import "./PlainText.css";

export default function PlainText(data: {
    children: string;
    darkBackground?: boolean;
    className?: string;
}) {
    return (
        <div
            className={
                "plaintext-wrap w-full px-4 pb-4 text-reversible prose" +
                (data.darkBackground ? " bg-deep-blue text-white" : "")
            }
        >
            {data.children.split("\n").map((child, index) => (
                <p key={index} className={"w-full" + (data.className || "")}>
                    {child}
                </p>
            ))}
        </div>
    );
}
