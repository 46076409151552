import { IonIcon } from "@ionic/react";
import { chevronDown } from "ionicons/icons";
import record from "../../lib/util/analytics";
import { Accordion as TAccordion } from "../../types/fields";
import Accordion from "../Accordion";
import "./ContentAccordion.css";

interface Props {
    items: TAccordion[];
    className?: string;
}

export default function ContentAccordion(props: Props) {

    // @ts-ignore
    const items = props.items.sort((i1, i2) => 0 - (i1.foldTitle.toLowerCase() < i2.foldTitle.toLowerCase()));

    return (
        <div className="content-accordion">
            {items.map((item, index) => {
                const headerEl = (
                    <div className="w-full flex flex-row flex-reversible flex-nowrap items-center justify-between py-1"
                        onClick={() => {
                            record("interaction", {
                                action: "click",
                                label: item.foldTitle
                            });
                        }}>
                        <h3
                            className={
                                "text-md font-black uppercase text-left text-reversible"
                            }
                        >
                            {item.foldTitle}
                        </h3>
                        <IonIcon
                            className="text-xl transition-all duration-300 text-deep-blue"
                            icon={chevronDown}
                        />
                    </div>
                );

                const bodyEl = (
                    <div className="pt-6 reversible prose"
                        dangerouslySetInnerHTML={{
                            __html: item.foldContent,
                        }}
                    />
                );

                return (
                    <Accordion
                        key={index}
                        className="mx-4 mb-4 accordion-item bg-white rounded-xl p-4 pl-4 pb-3 shadow-lg"
                        headerEl={headerEl}
                        bodyEl={bodyEl}
                    />
                );
            })}
        </div>
    );
}
