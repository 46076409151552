import { IonItem, IonLabel, IonRadio, IonImg } from "@ionic/react";

import { getUrl } from "../../lib/hooks/useCdn";

interface RadioListItemProps {
    value: string | number;
    label: string;
    image?: string;
    onclick?: any;
    selected?: boolean;
    classes?: string;
}

function RadioListItem(props: RadioListItemProps) {
    const height = props.image ? "h-16" : "h-12";
    const lineHeight = props.image ? "4.3rem" : "3.2rem";

    return (
        <IonItem
            key={props.value}
            color="light"
            className={
                `radio-list-item border border-blue-300 rounded-xl text-sm mb-3 mx-8 ${height}` +
                props.classes
            }
            lines="none"
            onClick={props.onclick.bind(null, props.value)}
        >
            {props.image && (
                <IonImg src={getUrl(props.image)} className="mr-4 h-8 w-8" />
            )}

            <IonLabel style={{"lineHeight": lineHeight}} className={"m-0 uppercase font-black text-sm " + height}>
                {props.label}
            </IonLabel>

            <IonRadio
                slot="end"
                // @ts-ignore
                checked={props.selected ? "checked" : ""}
                value={props.value}
            />
        </IonItem>
    );
}

export default RadioListItem;
