import clsx from "clsx"

export default function RichText(data: {
    children: string;
    darkBackground?: boolean;
    centreAlign?: boolean;
    className?: string;
}) {
    return (
        <div
            className={clsx(
                "richtext px-4 pb-4 reversible-content prose",
                data.darkBackground && "bg-deep-blue text-white",
                data.centreAlign && "text-center",
                data.className
            )}
            dangerouslySetInnerHTML={{ __html: data.children }}
        />
    );
}
