import { urlFromPath } from "../lib/hooks/useCdn";
import {
    Block,
    PlainTextBlock as PTBlock,
    RichTextBlock as RTBlock,
    AccordionBlock as ABlock,
    ImageCarouselBlock as ICBlock,
    IconBreakBlock as IBBlock,
    EmbeddedVideoBlock,
} from "../types/neo_content";
import AccordionBlock from "./blocks/AccordionBlock";
import IconBreakBlock from "./blocks/IconBreakBlock";
import ImageCarouselBlock from "./blocks/ImageCarouselBlock";
import PlainTextBlock from "./blocks/PlainTextBlock";
import RichTextBlock from "./blocks/RichTextBlock";
import EmbeddedVideo from "./fields/EmbeddedVideo";
import Image from "./Image/Image";

export default function NeoContent({
    blocks,
}: {
    blocks: Block[];
}): JSX.Element {
    return <>{blocks.map((block, i) => renderContentBlock(block, i))}</>;
}

function renderContentBlock(block: Block, key: number): JSX.Element | Element {
    switch (block.typeHandle) {
        case "plainText":
            return <PlainTextBlock key={key} block={block as PTBlock} />;

        case "richText":
            return <RichTextBlock key={key} block={block as RTBlock} />;

        case "accordion":
            return <AccordionBlock key={key} block={block as ABlock} />;

        case "imageCarousel":
            return <ImageCarouselBlock key={key} block={block as ICBlock} />;

        case "embeddedVideo":
            return (
                <EmbeddedVideo
                    key={key}
                    url={(block as EmbeddedVideoBlock).embeddedVideo}
                />
            );

        case "iconBreak":
            return <IconBreakBlock key={key} block={block as IBBlock} />;

        case "image":
            return (
                <Image
                    key={key}
                    classes="mx-4 mb-4"
                    path={urlFromPath((block as any).image)}
                ></Image>
            );

        default:
            const msg = "Neo content block type not implemented: " + block.typeHandle;
            console.warn(msg);
            return process.env.REACT_APP_ENVIRONMENT === "dev" ? (
                <div key={key}>{msg}</div>
            ) : (
                <></>
            );
    }
}
