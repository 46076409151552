export default function PageTitle({ children }: any) {
    return (
        <h1
            className={"font-black text-center uppercase mt-7 mb-4 mx-4"}
            style={{ fontSize: "1.7rem" }}
        >
            {children}
        </h1>
    );
}
