interface Props {
    contact: string;
    className?: string;
}

export default function ContactLink({ contact, className }: Props) {
    if (!contact.match(/^(tel|mailto):.+$/)) {
        console.warn("Invalid contact" + contact);
    }

    const contactVal = contact.split(":", 2).pop();

    function onclick() {
        window.open(contact, "_system", "location=yes");
    }

    return (
        <span
            className={"text-blue-400 underline " + (className || "")}
            onClick={onclick}
        >
            {contactVal}
        </span>
    );
}
