import { useRef } from "react";
import { IonIcon, IonImg, IonText } from "@ionic/react";
import { chevronForward, chevronBack } from "ionicons/icons";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import "swiper/css";
import record from "../lib/util/analytics";

SwiperCore.use([Navigation, Autoplay]);

export interface CarouselItem {
    title: string;
    date: string;
    image: string;
    credit: string;
}

const Carousel = ({ items }: { items: CarouselItem[] }) => {
    const prevRef = useRef(null);
    const nextRef = useRef(null);

    items = items.filter((item) => item.image !== "");

    if (items.length === 0) {
        return <></>;
    }

    return (
        <div className="carousel-wrap flex flex-row flex-nowrap items-center w-screen py-8 rounded-t-lg">
            <div className="ml-1 mr-2">
                <IonIcon
                    className="text-3xl text-orange"
                    icon={chevronBack}
                    onClick={() => {
                        record("interaction", {action: "back", component: "carousel"})
                    }}
                    ref={prevRef}
                />
            </div>
            <Swiper
                autoplay={{ delay: 5000, disableOnInteraction: false }}
                slidesPerView={1}
                spaceBetween={0}
                navigation={{
                    nextEl: nextRef.current,
                    prevEl: prevRef.current,
                }}
            >
                {items.map((item, index) => {
                    return (
                        <SwiperSlide key={index} className="h-full">
                            <div className="w-full h-full text-center flex flex-col flex-nowrap rounded-md bg-white">
                                <IonText className="text-xl text-deep-blue font-black mt-4">
                                    {item.title}
                                </IonText>
                                <IonText className="text-xs text-deep-blue font-normal pb-2">
                                    {item.date}
                                </IonText>
                                <IonImg
                                    src={item.image}
                                    className="flex-grow"
                                />
                                <IonText className="text-xs text-left text-reversible text-deep-blue font-normal px-4 py-2">
                                    {item.credit}
                                </IonText>
                            </div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <div className="ml-1 mr-2">
                <IonIcon
                    className="text-3xl text-orange"
                    icon={chevronForward}
                    onClick={() => {
                        record("interaction", {action: "forward", component: "carousel"})
                    }}
                    ref={nextRef}
                />
            </div>
        </div>
    );
};

export default Carousel;
