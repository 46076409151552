import { IonApp, IonPage, IonContent, IonIcon } from "@ionic/react";
import "./SplashScreen.css";

interface Props {
    splashTimeout: Date;
    setSplashTimeout: any;
    productOfText: string;
}

export default function SplashScreen({
    splashTimeout,
    setSplashTimeout,
    productOfText,
}: Props) {
    const splashDiff = splashTimeout.valueOf() - Date.now();

    if (splashDiff >= 0) {
        setTimeout(setSplashTimeout.bind(null, new Date(0)), splashDiff);
    }

    return (
        <IonApp>
            <IonPage>
                <IonContent className="splash-bg">
                    <div className="h-full flex flex-col justify-evenly">
                        <IonIcon
                            className="w-full text-center text-8xl"
                            src="images/FFF.svg"
                        />
                        <div>
                            <h4 className="text-center">{productOfText || "A product of"}</h4>
                            <div className="flex flex-row flex-nowrap items-center justify-center">
                                <IonIcon
                                    className="text-center text-7xl mr-6"
                                    src="images/GFN.svg"
                                />
                                <IonIcon
                                    className="text-center"
                                    style={{fontSize: "6rem"}}
                                    src="assets/icon/wf_logo_white.svg"
                                />
                            </div>
                        </div>
                    </div>
                </IonContent>
            </IonPage>
        </IonApp>
    );
}
