import { setRegion } from "../../lib/util/user";
import RadioList from "./RadioList";
import { CountryData } from "../../types/startup_types";
import record from "../../lib/util/analytics";

interface RegionSelectProps {
    countries: {
        [key: string]: CountryData;
    };
}

function RegionSelect({ countries }: RegionSelectProps) {

    let data = [];

    for (let code in countries) {
        data.push({
            value: code,
            image: countries[code].image,
            label: countries[code].name,
        });
    }

    return (
        <>
            <h1 className="text-center text-lg mt-24 mb-4 font-black">
                Country
            </h1>
            <RadioList
                items={data}
                className="region-select-list"
                onclick={(region: string) => {
                    if (region) {
                        record("interaction", {
                            category: "country_locator",
                            action: "click",
                            region: region,
                        });

                        setRegion(region);
                    }
                }}
            />
        </>
    );
}

export default RegionSelect;
