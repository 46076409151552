import { setLocale, resetData } from "../../lib/util/user";
import RadioList from "./RadioList";

import { CountryData } from "../../types/startup_types";
import RadioListItem from "./RadioListItem";
import record from "../../lib/util/analytics";
import { IonIcon, useIonAlert } from "@ionic/react";
import { chevronBack } from "ionicons/icons";
import store from "../../lib/util/storage";

interface LocaleSelectProps {
    country: CountryData;
    startupData: any;
    setSplashTimeout: any;
}

function LocaleSelect({
    country,
    startupData,
    setSplashTimeout,
}: LocaleSelectProps) {
    const [present] = useIonAlert();

    const data = country.locales.map((locale) => {
        return {
            value: locale.locale,
            label: locale.displayName,
        };
    });

    function changeCountry() {
        record("interaction", { category: "country_locator", action: "back" });
        resetData();
    }

    function onLanguageClick(locale: string) {
        // @ts-ignore
        document.querySelector(".locale-select-list > ion-radio-group").value =
            "";

        store.get("user_data").then((data) => {
            if (typeof data.prevLocale !== "string") {
                changeLocale(locale);
                return;
            }

            const alertData =
                startupData.locale_settings[data.prevLocale] ||
                startupData.localeSettings["en-AU"];

            present({
                cssClass: "lang-change-alert",
                mode: "ios",
                header: alertData.changeLanguageHeading || "Change region/language",
                message: alertData.changeLanguageMessage || "Are you sure you want to change your preferences?",
                buttons: [
                    alertData.changeLanguageCancelText || "Cancel",
                    {
                        text: alertData.changeLanguageConfirmText || "Confirm",
                        handler: (d) => {
                            changeLocale(locale);
                        },
                    },
                ],
            });
        });
    }

    function changeLocale(locale: string) {
        record("interaction", {
            action: "select_language",
            locale: locale,
        });
        setLocale(locale);
        setSplashTimeout(new Date(Date.now() + 2000));
    }

    return (
        <>
            <h1 className="text-center text-lg mt-8 mb-4 font-black">
                Country
            </h1>
            <RadioListItem
                value="lang"
                label={country.name}
                image={country.image}
                selected={true}
                classes="country-item mt-4"
                onclick={changeCountry}
            />
            <div className="text-orange flex-center" onClick={changeCountry}>
                <IonIcon icon={chevronBack} className="text-xl mr-2" />
                <label className="mt-1 font-bold uppercase">Change</label>
            </div>
            <h1 className="text-center text-lg mt-12 mb-4 font-black">
                {country.languageSelectLabel}
            </h1>
            <RadioList
                items={data}
                className="pb-12 locale-select-list"
                onclick={(locale: string) => {
                    onLanguageClick(locale);
                }}
            />
        </>
    );
}

export default LocaleSelect;
