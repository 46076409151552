import {
    ContentPageContent,
    GhanaHelpPageContent,
    HelpPageContent,
    MenuPageContent,
    PageContent,
} from "../../types/content";

export default function loadPages(raw: any): PageContent[] {
    let pageContents: PageContent[] = [];

    for (let slug in raw) {
        const pageData = { ...raw[slug] };

        pageData.pageHeading = pageData.plainText;
        pageData.subMenu = pageData.subMenu
            ? loadPages(pageData.subMenu)
            : undefined;

        switch (pageData.typeHandle) {
            case "menuPage":
                pageContents.push(pageData as MenuPageContent);
                // pageContents.push(loadMenuPage(page, pageData));
                break;

            case "contentPage":
                pageContents.push(pageData as ContentPageContent);
                // pageContents.push(loadContentPage(page, pageData));
                break;

            case "helpPage":
                pageContents.push(pageData as HelpPageContent);
                // pageContents.push(loadHelpPage(page, pageData));
                break;

            case "ghanaHelpPage":
                pageContents.push(pageData as GhanaHelpPageContent);
                // pageContents.push(loadGhanaHelpPage(page, pageData));
                break;
        }
    }

    return pageContents;
}
