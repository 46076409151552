import { IonIcon } from "@ionic/react";
import { chevronForward } from "ionicons/icons";

interface Props {
    className?: string;
}

export function NavCircle({ className }: Props) {
    return (
        <span
            className={
                "nav-circle bg-orange rounded-full mx-2 inline-flex flex-center " +
                (className || "")
            }
            style={{
                minWidth: "1.8rem",
                minHeight: "1.8rem",
                maxWidth: "1.8rem",
                maxHeight: "1.8rem",
            }}
        >
            <IonIcon
                icon={chevronForward}
                className="icon-flippable text-white text-xl transition-all duration-300 ease-in-out"
            />
        </span>
    );
}
