import { mutate } from "swr";
import store from "./storage";

export async function setUserData(
    region: string | null,
    locale: string | null
) {
    const prevData = await store.get("user_data");

    const newData = {
        region: region,
        locale: locale,
        prevLocale: prevData.locale || prevData.prevLocale || null,
    };

    await store.set("user_data", newData);

    mutate("user_data");

    return new Promise(function(success, fail) {
        success(newData);
    });
}

export async function setRegion(region: string | null) {
    const prevData = await store.get("user_data");

    const res = await store.set("user_data", {
        ...prevData,
        region: region,
    });

    mutate("user_data");

    return res;
}

export async function setLocale(locale: string | null) {
    const prevData = await store.get("user_data");

    const res = await store.set("user_data", {
        ...prevData,
        locale: locale,
        prevLocale: prevData.locale,
    });

    mutate("user_data");

    return res;
}

export function resetData() {
    return setUserData(null, null);
}
