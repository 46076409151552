import { IonIcon } from "@ionic/react";
import { chevronForward } from "ionicons/icons";
import { PageContent } from "../../types/content";

interface Props {
    pages: PageContent[] | undefined;
}

export default function HomePageChildItems({ pages }: Props) {

    if (typeof pages === "undefined" || pages.length === 0) {
        return <></>;
    }

    return (
        <div className="pt-3 pb-2">
            {pages.map((page, index) => (
                <div
                    key={index}
                    className="flex flex-row items-center justify-between reversible px-4 py-3"
                    onClick={() => {

                        const parentUrl = `/page/${page.parent.slug}`;
                        const url = `${parentUrl}/${page.slug}`;

                        // @ts-ignore
                        document.querySelector(`.main-menu .global-accordion-group .item[data-url="${url}"]`).click()
                    }}
                >
                    <span className="font-bold uppercase">
                        {page.pageHeading}
                    </span>
                    <span className="w-8" />
                    <div
                        className="bg-orange rounded-full flex items-center justify-center"
                        style={{
                            minWidth: "1.8rem",
                            minHeight: "1.8rem",
                        }}
                    >
                        <IonIcon
                            icon={chevronForward}
                            className="icon-flippable text-xl text-white"
                        />
                    </div>
                </div>
            ))}
        </div>
    );
}
