// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Image_imageContainer__qDfJn {\n    width: 100%;\n}\n\n.Image_imageContainer__qDfJn div {\n    position: unset !important;\n}\n\n.Image_image__82F1s {\n    object-fit: contain;\n    width: 100% !important;\n    position: relative !important;\n    height: unset !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Image/Image.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,mBAAmB;IACnB,sBAAsB;IACtB,6BAA6B;IAC7B,wBAAwB;AAC5B","sourcesContent":[".imageContainer {\n    width: 100%;\n}\n\n.imageContainer div {\n    position: unset !important;\n}\n\n.image {\n    object-fit: contain;\n    width: 100% !important;\n    position: relative !important;\n    height: unset !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageContainer": "Image_imageContainer__qDfJn",
	"image": "Image_image__82F1s"
};
export default ___CSS_LOADER_EXPORT___;
