import { ImagePath } from "../../types/fields";
import { CONTENT_URL } from "../util/constants";

export const getUrl = (path: string) => CONTENT_URL + path;

export function urlFromPath(image: ImagePath[]): string {
    try {
        return getUrl(image[0].path);
    } catch (e) {
        return "";
    }
}
