import { IonImg, IonText } from "@ionic/react";
import { urlFromPath } from "../../lib/hooks/useCdn";
import {
    ContactHeading,
    ContactPhone,
    ContactEmail,
    ContactDescription,
} from "../../types/fields";
import { CountryBlock } from "../../types/neo_help_list";
import ContactLink from "../ContactLink";
import RichText from "../fields/RichText";

export default function CountryHelpInfo({
    country,
}: {
    country: CountryBlock;
}) {
    const contactInfo = country.contactList.map((contact, index) => {
        switch (contact.typeHandle) {
            case "contactHeading":
                return (
                    <h3 key={index} className="font-black text-xl mb-4">
                        {(contact as ContactHeading).heading}
                    </h3>
                );

            case "contactPhone":
                const phone = contact as ContactPhone;
                return (
                    <p key={index}>
                        <strong>{phone.contactLabel}</strong>
                        <br />
                        <ContactLink contact={phone.phoneNumber} />
                    </p>
                );
            case "contactEmail":
                const email = contact as ContactEmail;
                return (
                    <p key={index}>
                        <strong>{email.emailLabel}</strong>
                        <br />
                        <ContactLink contact={email.emailAddress} />
                    </p>
                );
            case "contactDescriptionBlock":
                const desc = contact as ContactDescription;
                return (
                    <RichText key={index} className="p-0 px-0">
                        {desc.contactDescription}
                    </RichText>
                );
        }

        return <></>;
    });

    return (
        <div className="mx-4 mb-4 text-reversible">
            <div className="flex flex-row flex-reversible flex-nowrap items-center mb-8">
                <IonImg
                    src={urlFromPath(country.image)}
                    className="w-16 h-16 mx-4"
                />
                <IonText className="text-2xl mt-1 ml-4 font-black uppercase">
                    {country.plainText}
                </IonText>
            </div>
            <div className="bg-white rounded-xl p-4 contact-list prose">
                {contactInfo}
            </div>
        </div>
    );
}
