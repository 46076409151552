import { IonImg } from "@ionic/react";
import { urlFromPath } from "../../lib/hooks/useCdn";
import { GhanaContactsBlock } from "../../types/content";
import GhanaHelpItem from "./GhanaHelpItem";

export default function GhanaHelpList({
    region,
}: {
    region: GhanaContactsBlock;
}) {
    return (
        <div className="ghana-region-info">
            <div className="flex flex-row flex-reversible items-center mx-4 mb-4">
                <IonImg
                    src={urlFromPath(region.image)}
                    className="h-20 w-20 mx-4"
                />
                <div className="flex flex-col flex-nowrap">
                    <p className="text-2xl font-black uppercase">
                        {region.heading}
                    </p>
                    <p className="text-lg font-black text-reversible uppercase">
                        {region.subHeading}
                    </p>
                </div>
            </div>
            <div className="reversible">
                {region.ghanaContacts.map((contact, key) => (
                    <GhanaHelpItem key={key} contact={contact} />
                ))}
            </div>
        </div>
    );
}
